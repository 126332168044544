@use "base/mixins" as *;

@include max(tablet-landscape) {
  .character-elements {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    inset: auto 0 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: min(40%, 280px);
    transform: translate(-170px, 8vh);
  }
}

@include min(tablet-landscape) {
  .character-elements {
    pointer-events: none;
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    width: min(96%, 1500px);
    height: fit-content;

    .image-container--character-one {
      width: min(26%, 380px);
      filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.35));
    }
  }
}
