.svg-icon {
  &--logo-crasher {
    width: clamp(160px, 22vmin, 300px);
    aspect-ratio: 300 / 68;
  }

  &--pegi {
    display: block;
    margin: auto;
    width: 28px;
    height: 22px;
    transform: translateY(-5px);
  }
}
