.gain-message {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 0 1vw;
  justify-self: center;
  width: min(90vw, 660px);
  margin-top: var(--v-offset-lt);
  perspective: 110vw;
  transform-style: preserve-3d;

  &__item {
    transition: opacity 0.75s ease-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform-origin: bottom center;

    &:first-child {
      opacity: 0;
      animation: none;

      body.first-spin-complete & {
        opacity: 1;
        animation: flip-in-top 0.75s ease forwards;
      }
    }

    &:last-child {
      opacity: 0;
      animation: none;

      body.second-spin-complete & {
        opacity: 1;
        animation: flip-in-top 0.75s ease forwards;
      }
    }
  }

  @keyframes flip-in-top {
    0% {
      transform: perspective(110vw) rotateX(-90deg) translateY(70%);
      opacity: 0;
    }
    100% {
      transform: perspective(110vw) rotateX(0) translateY(0);
      opacity: 1;
    }
  }
}

//.gain-message {
//  position: relative;
//  text-align: center;
//  text-wrap: balance;
//
//  &__item {
//    position: relative;
//    margin-left: auto;
//    margin-right: auto;
//    width: fit-content;
//    color: White;
//    line-height: 1;
//    font-size: 4.8em;
//    font-weight: 900;
//
//    &:nth-child(2) {
//      color: White;
//      transform: rotate(-2.5deg);
//      animation: libration 1250ms infinite alternate ease-in-out;
//
//      @-webkit-keyframes libration {
//        to {
//          transform: rotate(2.5deg);
//        }
//      }
//    }
//
//    &:nth-child(3) {
//      color: White;
//      transform: rotate(-1.5deg);
//      animation: libration 1250ms infinite alternate ease-in-out;
//
//      @-webkit-keyframes libration {
//        to {
//          transform: rotate(1.5deg);
//        }
//      }
//    }
//
//    &:not(:first-of-type) {
//      margin-top: -1em;
//    }
//
//    .char {
//      will-change: transform;
//      backface-visibility: hidden;
//    }
//
//    .word,
//    .char {
//      display: inline-block;
//    }
//
//    .whitespace {
//      white-space: pre;
//    }
//
//    &.slide-null .char {
//      transform: translateY(-0.5em) rotateX(-90deg);
//    }
//
//    &.slide-in .char {
//      transform: translateY(0) rotateX(0deg);
//      animation: slide-in 425ms cubic-bezier(.3, 0, .7, 1) both;
//      animation-delay: calc(50ms * var(--char-index));
//
//      @keyframes slide-in {
//        to {
//          transform: translateY(-0.5em) rotateX(-90deg);
//        }
//      }
//    }
//
//    &.slide-out .char {
//      transform: translateY(0.5em) rotateX(90deg);
//      animation: slide-out 425ms cubic-bezier(.3, 0, .7, 1) both;
//      animation-delay: calc(50ms * var(--char-index));
//
//      @keyframes slide-out {
//        to {
//          transform: translateY(0) rotateX(0deg);
//        }
//      }
//    }
//  }
//}
