@mixin wheel-center($inset...) {
  position: absolute;
  inset: $inset;
  margin: auto;
}

.wheel-fortune {
  --wheel-total-size: clamp(340px, 92vmin, 540px);

  --wheel-arrow-size: 10%;
  --wheel-arrow-translate: -16%;

  --wheel-decrease-backing: 10px;
  --wheel-decrease-segments: 7%;
  --wheel-decrease-blackout: 7%;
  --wheel-decrease-blurring: 7%;
  --wheel-decrease-frame: 0;
  --wheel-decrease-arrow: 0 0 auto;
  --wheel-decrease-button: 25%;

  --wheel-blackout-color: rgb(10, 10, 10);
  --wheel-backing-color: rgb(230, 230, 230);

  position: relative;
  width: var(--wheel-total-size);
  height: var(--wheel-total-size);

  &__backing {
    @include wheel-center(var(--wheel-decrease-backing));

    background-color: var(--wheel-backing-color);
    border-radius: 100%;
  }

  &__segments {
    @include wheel-center(var(--wheel-decrease-segments));

    overflow: hidden;
    will-change: transform;
    border-radius: 100%;
  }

  &__blackout {
    --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
    --color: var(--wheel-blackout-color);

    @include wheel-center(var(--wheel-decrease-blackout));

    border-radius: 100%;
    opacity: var(--blackout-opacity, 0);
    background: conic-gradient(from 0deg, var(--color) var(--cutout), transparent var(--cutout));
    transform: rotate(calc((360deg / var(--blackout-angle, 8)) / 2));
  }

  &__blurring {
    @include wheel-center(var(--wheel-decrease-blurring));

    border-radius: 100%;
    backdrop-filter: blur(var(--blurring));
    backface-visibility: hidden;
  }

  &__frame {
    @include wheel-center(var(--wheel-decrease-frame));
  }

  &__arrow {
    @include wheel-center(var(--wheel-decrease-arrow));

    width: var(--wheel-arrow-size);
    height: var(--wheel-arrow-size);
    transform: translateY(var(--wheel-arrow-translate));
  }

  &__button {
    @include wheel-center(var(--wheel-decrease-button));

    --opacity-spin-wheel: 1;
    --opacity-spin-again: 0;

    cursor: pointer;
    filter: grayscale(0) brightness(1) drop-shadow(0 0 1em rgba(0, 0, 0, 0.9));
    transition: filter 425ms ease-in-out;

    .image-container--spin-wheel {
      opacity: var(--opacity-spin-wheel);
    }

    .image-container--spin-again {
      opacity: var(--opacity-spin-again);
    }

    .is-spinning &,
    .end-last-spin & {
      pointer-events: none;
      transition: filter 425ms ease-in-out;
      filter: grayscale(0.4) brightness(0.4) drop-shadow(0 0 1em rgba(0, 0, 0, 0.9));
    }

    body.first-spin-complete & {
      --opacity-spin-wheel: 0;
      --opacity-spin-again: 1;
    }
  }
}
