.main-slogan {
  text-align: center;
  font-family: "Oswald", serif;
  font-weight: 900;
  text-transform: uppercase;
  transform: translateY(35%);
  transition: transform 500ms ease;

  body.first-spin-complete & {
    transform: translateY(0);
  }

  .display-medium {
    margin: 0;
    font-size: 6.2em;
    color: #00B147;
  }

  .display-small {
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: 4.2em;
    color: #FFFFFF;

    span {
      margin-left: 0.8em;
      padding: 0 0.8em;
      border-radius: 3.5px;
      background: linear-gradient(#00B147, #008E39);
      font-size: 0.5em;
    }
  }
}
