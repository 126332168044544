@use "base/mixins" as *;

.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--gain-message-one,
  &--gain-message-two {
    img {
      object-fit: contain;
    }
  }

  &--spin-wheel,
  &--spin-again {
    position: absolute;
    inset: 0;
    margin: auto;
    transition: opacity 725ms ease-in-out;
  }

  &--page-background {
    position: fixed;
    inset: 0;
    z-index: -1;
    margin: auto;
    width: min(100vw, 2560px);
    height: 100vh;
  }

  &--character-one {
    img {
      object-position: left center;
      object-fit: contain;
    }
  }

  &--character-two {
    img {
      object-position: right center;
      object-fit: contain;
    }
  }

  &--bonus-banner {
    img {
      object-position: left center;
      object-fit: cover;
      overflow: hidden;
      border-radius: 7.5px;
    }
  }
}
